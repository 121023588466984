import Head from 'components/admin/Head';
import Pagination from 'components/admin/Pagination';
import Table from 'components/admin/Table';

const AdminContainer = () => {
  return (
    <main>
      <div className="body">
        <Head />
        <Table />
        <div className="block my-[30px]">
          <Pagination />
        </div>
      </div>
    </main>
  );
};

export default AdminContainer;
