import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import adminReducer from 'store/reducers/admin';
import { adminApi } from 'services/admin';

const rootReducer = combineReducers({
  [adminApi.reducerPath]: adminApi.reducer,
  admin: adminReducer.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      // logger,
      adminApi.middleware,
    ),
  devTools: process.env.NODE_ENV === 'development',
});

setupListeners(store.dispatch); // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
export type IRootState = ReturnType<typeof rootReducer>;
