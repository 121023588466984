import { useLazyGetBookListQuery } from 'services/admin';
import { IRootState } from 'store/store';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import adminSlice from 'store/reducers/admin';

const Head = () => {
  const dispatch = useDispatch();

  const { svcCd } = useSelector(({ admin }: IRootState) => ({
    svcCd: admin.svcCd,
  }));

  return (
    <div className="flex mb-[28px]">
      <span className="flex self-center font-nanumgothic font-[800] text-[22px] mr-[28px]">
        나만의 드림라이팅 책장 목록
      </span>
      <button className={`button-item ${svcCd === 'Aiwrite' ? 'active' : ''}`} onClick={() => dispatch(adminSlice.actions.changeSvcCd('Aiwrite'))}>상상글</button>
      <button className={`button-item ${svcCd === 'Aithink' ? 'active' : ''}`} onClick={() => dispatch(adminSlice.actions.changeSvcCd('Aithink'))}>생각글</button>
      <button className={`button-item ${svcCd === 'Aiquiz' ? 'active' : ''}`} onClick={() => dispatch(adminSlice.actions.changeSvcCd('Aiquiz'))}>단어사전</button>
    </div>
  );
};

export default Head;
