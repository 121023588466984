import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAdminState } from 'interfaces/admin';

const initialState: IAdminState = {
  studId: 0,
  pId: '',
  currentpage: 1,
  pageSize: 10,
  totalPage: 0,
  svcCd: 'Aiwrite',
}

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clear: () => initialState,
    setPId: (state, action: PayloadAction<string>) => {
      state.pId = action.payload;
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.currentpage = action.payload;
    },
    changePageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    changetotalPage: (state, action: PayloadAction<number>) => {
      state.totalPage = Math.ceil(action.payload / state.pageSize);
    },
    changeSvcCd: (state, action: PayloadAction<'Aiwrite' | 'Aithink' | 'Aiquiz'>) => {
      state.currentpage = 1;
      state.svcCd = action.payload;
    },
  },
});

export default adminSlice;