import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { proxyUrlPath } from ".";
import {
  IGetBookListReq,
  IGetBookListRes,
} from 'interfaces/admin'

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: proxyUrlPath + '/admin/',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Admin'],
  endpoints: builder => ({
    getBookList: builder.query<IGetBookListRes, IGetBookListReq>({
      query: ({ studId, page, p:pId, pageSize, svcCd }) => `${process.env.REACT_APP_API_SERVER}/aiwrite/admin/books/lists/${studId}/${page}?p=${pId}&pageSize=${pageSize}&svcCd=${svcCd}`,
      // query: ({ studId, page, p:pId, pageSize, svcCd }) => `http://dev.aiwrite.i-screamreport.com/aiwrite/admin/books/lists/${studId}/${page}?p=${pId}&pageSize=${pageSize}&svcCd=${svcCd}`,
      providesTags: () => [{type : 'Admin', id: 'getBookList'}],
    }),
  })
})

export const { useLazyGetBookListQuery } = adminApi;