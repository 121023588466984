import AdminContainer from "containers/AdminContainer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import adminSlice from "store/reducers/admin";

const AdminPage = () => {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { p } = queryString.parse(search);

  if (p) {
    dispatch(adminSlice.actions.setPId(p as string))
  }
   
  useEffect(() => {
    
  },[])

  return <AdminContainer />
}

export default AdminPage;