import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import AdminPage from './pages/AdminPage';

function App() {
  return (
    <Suspense
      fallback={
        <div>
          loading...
        </div>
      }>
        <BrowserRouter>
          <Switch>
            <Route path='/' component={AdminPage} exact></Route>
          </Switch>
        </BrowserRouter>
      </Suspense>
  );
}

export default App;
