import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../store/store";
import adminSlice from "../../store/reducers/admin";

const Pagination = () => {
  const dispatch = useDispatch();
  const [pageArray, setPageArray] = useState<number[]>([]);

  const { studId, pId, currentpage, pageSize, totalPage } = useSelector(
    ({ admin }: IRootState) => ({
      studId: admin.studId,
      pId: admin.pId,
      currentpage: admin.currentpage,
      pageSize: admin.pageSize,
      totalPage: admin.totalPage,
    })
  );

  useEffect(() => {
    let array: number[] = [];

    Array.from(Array(totalPage), (e, i) => {
      if (i < 10) {
        array.push(i + 1);
      }
    });

    setPageArray(array);
  }, [totalPage]);

  const onClickPagination = (e: any, value: number) => {
    e.preventDefault();
    dispatch(adminSlice.actions.changePage(value));
  };

  const onClickNext = (e: any) => {
    e.preventDefault();
    if (!(currentpage >= totalPage)) {
      if (pageArray[pageArray.length - 1] === currentpage && totalPage > 10) {
        let array: number[] = [];

        Array.from(Array(totalPage), (e, i) => {
          if (i < 10) array.push(pageArray[i] + 1);
        });

        setPageArray(array);
      }
      dispatch(adminSlice.actions.changePage(currentpage + 1));
    }
  };

  const onClickEnd = (e: any) => {
    e.preventDefault();
    if (!(currentpage === totalPage)) {
      dispatch(adminSlice.actions.changePage(totalPage));
    }
    if (totalPage > 10) {
      let array: number[] = [];

      Array.from(Array(totalPage), (e, i) => {
        if (i < 10) array.push(totalPage - 9 + i);
      });

      setPageArray(array);
    }
  };

  const onClickPrev = (e: any) => {
    e.preventDefault();
    if (currentpage !== 1) {
      if (pageArray[0] === currentpage && totalPage > 10) {
        let array: number[] = [];

        Array.from(Array(totalPage), (e, i) => {
          if (i < 10) array.push(pageArray[i] - 1);
        });

        setPageArray(array);
      }
      dispatch(adminSlice.actions.changePage(currentpage - 1));
    }
  };

  const onClickStart = (e: any) => {
    e.preventDefault();
    if (!(currentpage === 1)) {
      dispatch(adminSlice.actions.changePage(1));
    }

    if (totalPage > 10) {
      let array: number[] = [];

      Array.from(Array(totalPage), (e, i) => {
        if (i < 10) array.push(i + 1);
      });

      setPageArray(array);
    }
  };

  const onClickPrevTen = (e: any) => {
    e.preventDefault();
    if (pageArray[0] - 10 > 0) {
      let array: number[] = [];

      Array.from(Array(totalPage), (e, i) => {
        if (i < 10) {
          array.push(pageArray[i] - 10);
        }
      });

      setPageArray(array);
    } else {
      let array: number[] = [];

      Array.from(Array(totalPage), (e, i) => {
        if (i < 10) {
          array.push(i + 1);
        }
      });

      setPageArray(array);
    }
  };

  const onClickNextTen = (e: any) => {
    e.preventDefault();
    if (pageArray[pageArray.length - 1] + 10 < totalPage) {
      let array: number[] = [];

      Array.from(Array(totalPage), (e, i) => {
        if (i < 10) {
          array.push(pageArray[i] + 10);
        }
      });

      setPageArray(array);
    } else {
      let array: number[] = [];

      Array.from(Array(totalPage), (e, i) => {
        if (i < 10) array.push(totalPage - 9 + i);
      });

      setPageArray(array);
    }
  };

  return (
    <div className="text-center font-nanumgothic font-[400] text-[13px]">
      <span className="">
        <a className="page-item" href="#" onClick={onClickStart}>
          {`<<`}
        </a>
      </span>
      <span className="">
        <a className="page-item" href="#" onClick={onClickPrev}>
          {`<`}
        </a>
      </span>
      {pageArray[0] > 1 ? (
        <span className="">
          <a className="page-item" href="#" onClick={onClickPrevTen}>
            …
          </a>
        </span>
      ) : null}
      {pageArray.map((value, index) => {
        return (
          <span key={index} className="">
            <a
              className={`page-item ${value === currentpage ? "active" : ""}`}
              href="#"
              onClick={(e) => onClickPagination(e, value)}
            >
              {value}
            </a>
          </span>
        );
      })}
      {pageArray[pageArray.length - 1] < totalPage ? (
        <span className="">
          <a className="page-item" href="#" onClick={onClickNextTen}>
            …
          </a>
        </span>
      ) : null}
      <span className="">
        <a className="page-item" href="#" onClick={onClickNext}>
          {`>`}
        </a>
      </span>
      <span className="">
        <a className="page-item" href="#" onClick={onClickEnd}>
          {`>>`}
        </a>
      </span>
    </div>
  );
};

export default Pagination;
