import { useLazyGetBookListQuery } from 'services/admin';
import { IRootState } from 'store/store';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import adminSlice from 'store/reducers/admin';

const Table = () => {
  const dispatch = useDispatch();

  const { studId, pId, currentpage, pageSize, totalPage, svcCd } = useSelector(({ admin }: IRootState) => ({
    studId: admin.studId,
    pId: admin.pId,
    currentpage: admin.currentpage,
    pageSize: admin.pageSize,
    totalPage: admin.totalPage,
    svcCd: admin.svcCd,
  }));

  const [getBookListTrigger, { data: bookList, isFetching, isSuccess, isUninitialized }] = useLazyGetBookListQuery();

  useEffect(() => {
    getBookListTrigger({
      studId: studId.toString(),
      p: pId,
      page: currentpage.toString(),
      pageSize: pageSize.toString(),
      svcCd: svcCd,
    });
  }, [currentpage, svcCd]);

  useEffect(() => {
    if(bookList?.data) {
      dispatch(adminSlice.actions.changetotalPage(bookList.data.totalCnt));
    }
  }, [bookList])

  return (
    <table className="min-w-[920px] max-w-full font-nanumgothic w-[100%] table-auto">
      <thead className=" bg-[#ccc] break-words">
        <tr className=" font-[600] h-[40px] border-b-[1px] border-[#b7b7b7]">
          <th className="w-[19%]">등록일</th>
          <th className={`${svcCd === 'Aiwrite' ? 'w-[21%]' : 'w-[35%]'}`}>제목</th>
          {svcCd === 'Aiwrite' ? <th className="w-[21%]">키워드</th> : null}
          <th className="last w-[46%]">내용</th>
        </tr>
      </thead>
      <tbody>
        {bookList?.data ? (
          bookList?.data.bookList.map((value, index) => {
            return (
              <tr>
                <td>{value.dt}</td>
                <td>{value.title}</td>
          {svcCd === 'Aiwrite' ? <td>{value.keyword}</td> : null}
                <td className="last break-all">{value.content}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={4} className="w-[100%] h-[180px] align-middle border-r-[0px]">
              등록된 글이 없습니다
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
